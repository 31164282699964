
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  components: {
    ElDialog,
    ElButton,
  },
})
export default class AuthenticationLogoutIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/authentication/logout",
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        store.dispatch("setLogout");
        this.$router.push({
          name: "AuthenticationLoginIndexVue",
        });
      }
    });
    this.isLoading = false;
  }
}
