
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppCommonOrganization} from "@/models/app/common/organization";
import {AppCommonOrganizationMember} from "@/models/app/common/organization-member";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElButton,
  ElNotification,
} from "element-plus";
import {Setting} from "@element-plus/icons-vue";

// Services
import {getRequest, postRequest} from "@/services/api/request";
import {setNotification} from "@/services/app";

@Options({
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElButton,
    ElNotification,
    Setting,
  },
})
export default class SwitchOrganizationIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  organizations: Array<AppCommonOrganization> = [];
  organizationMember: AppCommonOrganizationMember = new AppCommonOrganizationMember();
  organizationMembership: Array<AppCommonOrganizationMember> = [];

  formData = {
    organization_id: "",
  };
  formDataRules = {
    organization_id: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: `/common/user/organization-membership/update`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        store.dispatch("setOrganizationMember", r.data);
        setNotification("Promjenili ste organizaciju", "success");
        window.location.reload();
      }
    });
    this.isLoading = false;
  }

  async getUserOrganizations(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/user/organizations`,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.organizations = r.data.organizations;
      }
    });
    this.isLoading = false;
  }

  created(): void {
    this.organizationMember = store.getters.getOrganizationMember;
    this.organizationMembership = store.getters.getOrganizationMembership;

    this.getUserOrganizations();
  }
}
